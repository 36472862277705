// src/components/ChartComponent.js
import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const ChartComponent = ({ chartData }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    console.log("chartData in ChartComponent:", chartData);  // Log chartData

    // Parse the chart_js_json string into a JavaScript object
    const chartConfig = JSON.parse(chartData.chart_js_json);

    const chart = new Chart(ctx, chartConfig);

    return () => {
      chart.destroy();
    };
  }, [chartData]);

  return <canvas ref={chartRef}></canvas>;
};

export default ChartComponent;
