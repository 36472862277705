// src/components/MarketEvaluator.jsx
import React, { useEffect, useState } from 'react';
import ChartComponent from './ChartComponent';
import HeatmapTable from './HeatmapTable';
import ChoroplethMap from './ChoroplethMap';
import api from '../api';

const marketChartNames = [
  'Estimate of Median Income for County',
  'Estimate of GDP for All Industries for County',
  'Estimate of Number of Private Establishments for County',
  'Estimate of Number of Resident Population for County',
  'Estimate of Population % with Equifax Subprime Credit for County',
  'Macro Economic Health Map',
  'Nearby Permits',
  'Nearby Permits Units',
  'Median Listing Price vs Average of Nearby Median Listing Prices',
  'Median Days on Market vs Average of Nearby Median Days on Market',
  'Active to Pending Listings Ratio',
  'Price Increases vs Price Decreases',
  'Household Distribution Owner vs Renter',
  'Household Income Distribution Owner vs Renter'
];

const MarketEvaluator = ({ selectedLocation, selectedDistance, selectedClient }) => {
  const [charts, setCharts] = useState([]);

  useEffect(() => {
    if (selectedLocation) {
      fetchChartsData(selectedLocation, selectedDistance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, selectedDistance]);

  const fetchChartsData = async (locationId, distance) => {
    const url = `/api/charts?locationId=${encodeURIComponent(locationId)}&selectedDistance=${encodeURIComponent(distance)}&client_id=${selectedClient}&` + marketChartNames.map(name => `chartNames=${name}`).join('&');
    try {
      const response = await api.get(url, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      const sortedCharts = response.data.sort((a, b) => {
        return marketChartNames.indexOf(a.chart_name) - marketChartNames.indexOf(b.chart_name);
      });

      setCharts(sortedCharts);
    } catch (error) {
      console.error('Error fetching charts data:', error);
      setCharts([]);
    }
  };

  return (
    <div id="charts-container" className="charts-container">
      {charts.map((chartData, index) => (
        <div key={index} className="chart-wrapper">
          <h2>{chartData.display_name || chartData.chart_name}</h2>
          {chartData.chart_name === 'Macro Economic Health Map' ? (
            <>
              <HeatmapTable chartData={chartData} />
              <br />
              <ChoroplethMap data={chartData} />
            </>
          ) : (
            <ChartComponent chartData={chartData} />
          )}
        </div>
      ))}
    </div>
  );
};

export default MarketEvaluator;
