  // src/components/MacroEconMap.jsx

  import React, { useRef, useEffect, useState } from 'react';
  import mapboxgl from 'mapbox-gl';
  import ColoredTableCell from './ColoredTableCell'; // Import the component
  import api from '../api';
  import 'mapbox-gl/dist/mapbox-gl.css'; // Import Mapbox GL CSS
  import './MacroEconMap.css'; // Import CSS for styling

  // Import the existing ChartComponent
  import ChartComponent from './ChartComponent';

  // Set your Mapbox access token using environment variables for security
  mapboxgl.accessToken = 'pk.eyJ1IjoibWljaGFlbC1ydCIsImEiOiJjbTFmY2gxZGYydjBhMmptejRkMXY3NnE4In0.HLgD2rXqws3hQ0Wr7X7PQw';

  // Define chart configurations outside the component to maintain a stable reference
  const chartConfigs = [
    {
      id: 'median_income',
      title: 'Median Household Income Over Time',
      endpoint: '/get-median-income-county',
    },
    {
      id: 'resident_population',
      title: 'Resident Population Over Time',
      endpoint: '/get-resident-population-county',
    },
    {
      id: 'subprime_population',
      title: 'Subprime Population Over Time',
      endpoint: '/get-subprime-population-county',
    },
    {
      id: 'gdp_all_industries',
      title: 'GDP All Industries Over Time',
      endpoint: '/get-gdp-all-industries-county',
    },
    {
      id: 'private_establishments',
      title: 'Private Establishments Over Time',
      endpoint: '/get-private-establishments-county',
    },
    {
      id: 'county_permits',
      title: 'Nearby Permits for Selected County',
      endpoint: '/get_permits_for_single_county_query',
    },
    {
      id: 'nearby_permits',
      title: 'Nearby Permits Based on Selected Distance',
      endpoint: '/get-nearby-permits',
      requiresDistance: true,
    },
    {
      id: 'county-median-listing-price',
      title: 'Median Listing Price for Selected County',
      endpoint: '/get-county-median-listing-price',
    },
    {
      id: 'county-median-days-on-market',
      title: 'Median Days on Market for Selected County',
      endpoint: '/get-county-median-days-on-market',
    },
    {
      id: 'get-county-active-pending-ratio',
      title: 'Active/Pending Listings Ratio for Selected County',
      endpoint: '/get-county-active-pending-ratio',
    },
    {
      id: 'get-county-price-increase-decrease',
      title: 'Price Increases vs Decreases for Selected County',
      endpoint: '/get-county-price-increase-decrease',
    }
  ];

  const MacroEconMap = ({ selectedDistance }) => {
    console.log("MacroEconMap component is rendering."); // Basic log
    const mapContainerRef = useRef(null); // Reference to the map container div
    const mapRef = useRef(null); // To store the map instance
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCounty, setSelectedCounty] = useState(null); // To store selected county data
    const [nearestCounties, setNearestCounties] = useState([]); // To store nearest counties data
    const [countyData, setCountyData] = useState({}); // To store local JSON data
    const [columnRanges, setColumnRanges] = useState({}); // To store min and max for each column
    const [showLegend, setShowLegend] = useState(false); // State to control legend visibility

    // State to manage chart data, loading, and errors for each chart
    const [charts, setCharts] = useState(
      chartConfigs.reduce((acc, chart) => {
        acc[chart.id] = { data: null, loading: false, error: null };
        return acc;
      }, {})
    );

    // Fetch local county data once when the component mounts
    useEffect(() => {
      const fetchCountyData = async () => {
        try {
          const response = await fetch('/data/county_data_2024_10_01.json');
          if (!response.ok) {
            throw new Error(`Failed to fetch county data: ${response.status} ${response.statusText}`);
          }
          const dataObj = await response.json();
          // Convert the JSON to a more usable format (array of objects)
          const formattedData = Object.keys(dataObj.fips).map(key => ({
            fips: dataObj.fips[key].toString().padStart(5, '0'),
            region_name: dataObj.region_name[key],
            population_harmonic_mean_percentile: dataObj.population_harmonic_mean_percentile[key],
            gdp_harmonic_mean_percentile: dataObj.gdp_harmonic_mean_percentile[key],
            establishments_harmonic_mean_percentile: dataObj.establishments_harmonic_mean_percentile[key],
            income_harmonic_mean_percentile: dataObj.income_harmonic_mean_percentile[key],
            employment_harmonic_mean_percentile: dataObj.employment_harmonic_mean_percentile[key],
            final_index_value: dataObj.final_index_value[key],
            final_index_percentile: dataObj.final_index_percentile[key],
          }));
          // Create a lookup object for quick access
          const lookup = {};
          formattedData.forEach(item => {
            lookup[item.fips] = item;
          });
          setCountyData(lookup);
          console.log("Local county data loaded:", lookup);
        } catch (err) {
          console.error(err);
          setError(err);
        }
      };

      fetchCountyData();
    }, []); // Empty dependency array ensures this runs once

    // Calculate min and max for each numerical column
    useEffect(() => {
      if (Object.keys(countyData).length === 0) return;

      const columnsToColor = [
        'population_harmonic_mean_percentile',
        'gdp_harmonic_mean_percentile',
        'establishments_harmonic_mean_percentile',
        'income_harmonic_mean_percentile',
        'employment_harmonic_mean_percentile',
        'final_index_value',
        'final_index_percentile'
      ];

      const ranges = {};

      columnsToColor.forEach(column => {
        const values = Object.values(countyData)
          .map(item => item[column])
          .filter(val => typeof val === 'number');

        if (values.length > 0) {
          const min = Math.min(...values);
          const max = Math.max(...values);
          ranges[column] = { min, max };
        } else {
          ranges[column] = { min: 0, max: 0 }; // Default values if no data
        }
      });

      setColumnRanges(ranges);
      console.log("Column ranges for color scaling:", ranges);
    }, [countyData]);

    // Initialize Mapbox map once countyData is loaded
    useEffect(() => {
      if (!countyData || Object.keys(countyData).length === 0) {
        console.log("County data not loaded yet.");
        return;
      }

      if (mapRef.current) {
        console.log("Map is already initialized.");
        return; // If map is already initialized, do nothing
      }

      if (!mapContainerRef.current) {
        console.error("mapContainerRef.current is null. Cannot initialize map.");
        setError(new Error("Map container not found."));
        setIsLoading(false);
        return;
      }

      // Initialize Mapbox map
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current, // Container ID
        style: 'mapbox://styles/mapbox/light-v11', // Map style to use
        center: [-98, 38.88], // Initial map center in [lng, lat]
        zoom: 3, // Initial zoom level
      });

      // Add zoom and rotation controls to the map
      mapRef.current.addControl(new mapboxgl.NavigationControl());

      // Load data and set up layers once the map loads
      mapRef.current.on('load', async () => {
        try {
          console.log("Map loaded. Fetching GeoJSON data...");
          // Fetch GeoJSON data for US counties
          const geoJsonResponse = await fetch('https://raw.githubusercontent.com/plotly/datasets/master/geojson-counties-fips.json');
          if (!geoJsonResponse.ok) {
            throw new Error(`Failed to fetch GeoJSON data: ${geoJsonResponse.status} ${geoJsonResponse.statusText}`);
          }
          const geojson = await geoJsonResponse.json();
          console.log("GeoJSON data fetched successfully.");

          // Merge your data with the GeoJSON features
          geojson.features.forEach(feature => {
            // Attempt to retrieve 'GEOID'; fallback to 'id' if 'GEOID' is missing
            const fips = feature.properties.GEOID || feature.id;

            if (!fips) {
              console.warn("Feature missing both GEOID and id:", feature);
              feature.properties.final_index_value = 'NA'; // Assign 'NA' for missing data
              feature.properties.final_index_percentile = 'NA';
              feature.properties.region_name = 'Unknown Region';
              feature.properties.population_harmonic_mean_percentile = 'NA';
              feature.properties.gdp_harmonic_mean_percentile = 'NA';
              feature.properties.establishments_harmonic_mean_percentile = 'NA';
              feature.properties.income_harmonic_mean_percentile = 'NA';
              feature.properties.employment_harmonic_mean_percentile = 'NA';
              return; // Skip further processing for this feature
            }

            if (countyData[fips]) {
              feature.properties.final_index_value = countyData[fips].final_index_value;
              feature.properties.final_index_percentile = countyData[fips].final_index_percentile;
              feature.properties.region_name = countyData[fips].region_name;
              feature.properties.population_harmonic_mean_percentile = countyData[fips].population_harmonic_mean_percentile || 'NA';
              feature.properties.gdp_harmonic_mean_percentile = countyData[fips].gdp_harmonic_mean_percentile || 'NA';
              feature.properties.establishments_harmonic_mean_percentile = countyData[fips].establishments_harmonic_mean_percentile || 'NA';
              feature.properties.income_harmonic_mean_percentile = countyData[fips].income_harmonic_mean_percentile || 'NA';
              feature.properties.employment_harmonic_mean_percentile = countyData[fips].employment_harmonic_mean_percentile || 'NA';
            } else {
              console.warn(`No data found for FIPS: ${fips}`);
              feature.properties.final_index_value = 'NA'; // Assign 'NA' for missing data
              feature.properties.final_index_percentile = 'NA';
              feature.properties.region_name = 'Unknown Region';
              feature.properties.population_harmonic_mean_percentile = 'NA';
              feature.properties.gdp_harmonic_mean_percentile = 'NA';
              feature.properties.establishments_harmonic_mean_percentile = 'NA';
              feature.properties.income_harmonic_mean_percentile = 'NA';
              feature.properties.employment_harmonic_mean_percentile = 'NA';
            }
          });

          // Check how many features have final_index_value as a number
          const featuresWithData = geojson.features.filter(f => typeof f.properties.final_index_value === 'number');
          console.log(`Total Features: ${geojson.features.length}, Features with Data: ${featuresWithData.length}`);

          // Add the enriched GeoJSON data as a new source to the map
          mapRef.current.addSource('counties', {
            type: 'geojson',
            data: geojson,
          });

          // Add a fill layer to visualize counties based on final_index_percentile
          mapRef.current.addLayer({
            id: 'counties-layer',
            type: 'fill',
            source: 'counties',
            layout: {},
            paint: {
              'fill-color': [
                'case',
                ['==', ['get', 'final_index_percentile'], 'NA'],
                '#cccccc', // Color for 'NA' final_index_percentile
                [
                  'interpolate',
                  ['linear'],
                  ['get', 'final_index_percentile'],
                  0, '#ffffcc',
                  20, '#c7e9b4',
                  40, '#7fcdbb',
                  60, '#41b6c4',
                  80, '#1d91c0',
                  100, '#225ea8',
                ],
              ],
              'fill-opacity': 0.75,
              'fill-outline-color': '#ffffff',
            },
          });

          // Show legend now that the map and data are loaded
          setShowLegend(true);

          // Create a popup instance for displaying information on hover
          const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
          });

          // Define the click event handler separately to allow re-attachment if needed
          const handleMapClick = async (e) => {
            const feature = e.features[0];
            const fips = feature.properties.GEOID || feature.id;
            const regionName = feature.properties.region_name || 'Unknown Region';

            console.log(`Clicked on county: ${regionName} (FIPS: ${fips})`);

            // Fetch the 19 nearest counties from the backend API
            try {
              const response = await api.get('/api/get-nearest-counties', {
                params: { fips: fips },
                withCredentials: true, // If required
              });

              // Access the data directly
              const data = response.data;

              if (data.nearest_counties) {
                setNearestCounties(data.nearest_counties);
                setSelectedCounty({
                  fips: fips,
                  region_name: regionName,
                });
              } else if (data.message) {
                setNearestCounties([]);
                setSelectedCounty({
                  fips: fips,
                  region_name: regionName,
                });
              }
            } catch (error) {
              if (error.response) {
                // Server responded with a status outside 2xx
                const errorData = error.response.data;
                setError(errorData.error || errorData.message || 'An error occurred.');
              } else if (error.request) {
                // Request was made but no response received
                setError('No response from the server. Please try again later.');
              } else {
                // Something else happened
                setError(error.message);
              }
            }

            // No need to fetch charts here anymore
          };

          // Attach the click event handler
          mapRef.current.on('click', 'counties-layer', handleMapClick);

          // Event listener for mouse movement over the counties layer
          mapRef.current.on('mousemove', 'counties-layer', (e) => {
            mapRef.current.getCanvas().style.cursor = 'pointer'; // Change cursor to pointer
            const feature = e.features[0];

            // Check if 'final_index_value' exists and is a number
            let htmlContent;
            if (typeof feature.properties.final_index_value === 'number') {
              htmlContent = `
                  <strong>${feature.properties.region_name || 'Unknown Region'}</strong><br/>
                  Final Index Value: ${feature.properties.final_index_value.toFixed(4)}<br/>
                  Realtorch Index Percentile: ${feature.properties.final_index_percentile.toFixed(2)}%<br/>
                  Population Percentile: ${feature.properties.population_harmonic_mean_percentile.toFixed(2)}%<br/>
                  GDP Percentile: ${feature.properties.gdp_harmonic_mean_percentile.toFixed(2)}%<br/>
                  Establishments Percentile: ${feature.properties.establishments_harmonic_mean_percentile.toFixed(2)}%<br/>
                  Income Percentile: ${feature.properties.income_harmonic_mean_percentile.toFixed(2)}%<br/>
                  Employment Percentile: ${feature.properties.employment_harmonic_mean_percentile.toFixed(2)}%
              `;
            } else {
              // Handle cases where 'final_index_value' is 'NA' or not a number
              htmlContent = `
                  <strong>${feature.properties.region_name || 'Unknown Region'}</strong><br/>
                  Final Index Value: NA<br/>
                  Realtorch Index Percentile: NA<br/>
                  Population Percentile: NA<br/>
                  GDP Percentile: NA<br/>
                  Establishments Percentile: NA<br/>
                  Income Percentile: NA<br/>
                  Employment Percentile: NA
              `;
            }
            popup.setLngLat(e.lngLat).setHTML(htmlContent).addTo(mapRef.current);
          });

          // Event listener for mouse leaving the counties layer
          mapRef.current.on('mouseleave', 'counties-layer', () => {
            mapRef.current.getCanvas().style.cursor = ''; // Reset cursor
            popup.remove(); // Remove the popup
          });

          setIsLoading(false); // Data loaded successfully
        } catch (error) {
          console.error('Error loading data:', error);
          setError(error);
          setIsLoading(false);
        }
      });

      // Cleanup function to remove the map on component unmount
      return () => {
        if (mapRef.current) {
          mapRef.current.remove();
          mapRef.current = null;
          console.log("Map has been removed.");
        }
        setShowLegend(false); // Hide legend when component unmounts
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countyData]); // Removed selectedDistance from dependencies

    // Fetch chart data whenever selectedDistance or selectedCounty changes
    useEffect(() => {
      if (!selectedCounty) return; // No county selected yet

      const fetchCharts = async () => {
        const { fips } = selectedCounty;

        // Create an array of promises for all chart fetches
        const fetchPromises = chartConfigs.map(async (chart) => {
          // Update loading state
          setCharts((prevCharts) => ({
            ...prevCharts,
            [chart.id]: { ...prevCharts[chart.id], loading: true, error: null, data: null },
          }));

          try {
            const params = { 'county-fips': fips };
            if (chart.requiresDistance) {
              params.distance = selectedDistance;
            }

            const chartResponse = await api.get(chart.endpoint, {
              params: params,
            });

            // Assuming chartResponse.data is a stringified JSON
            // Set chartData to match ChartComponent's expected prop structure
            setCharts((prevCharts) => ({
              ...prevCharts,
              [chart.id]: { ...prevCharts[chart.id], data: { chart_js_json: chartResponse.data }, loading: false },
            }));
          } catch (error) {
            console.error(`Error fetching chart data for ${chart.id}:`, error);
            setCharts((prevCharts) => ({
              ...prevCharts,
              [chart.id]: {
                ...prevCharts[chart.id],
                error: error.response?.data?.error || 'Failed to load chart data.',
                loading: false,
              },
            }));
          }
        });

        // Wait for all chart fetches to complete
        await Promise.all(fetchPromises);
      };

      fetchCharts();
    }, [selectedDistance, selectedCounty]); // Removed chartConfigs from dependencies

    return (
      <div className="container">
        <div className="map-container" ref={mapContainerRef}>
          {isLoading && (
            <div className="overlay">
              <p>Loading map...</p>
            </div>
          )}
          {error && (
            <div className="overlay error">
              <p>Error loading map: {error.message}</p>
            </div>
          )}
          {/* Conditionally render the legend */}
          {showLegend && (
            <div className="legend">
              {/* Create the vertical gradient bar */}
              <div className="legend-gradient"></div>
              {/* Add vertical labels */}
              <div className="legend-labels">
                {[0, 20, 40, 60, 80, 100].reverse().map(label => (
                  <span key={label}>{label}</span>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="table-container">
          {/* Display selected county and nearest counties data */}
          {selectedCounty && (
            <div className="county-info">
              <h3>Selected County: {selectedCounty.region_name} (FIPS: {selectedCounty.fips})</h3>
              {nearestCounties.length > 0 ? (
                <div>
                  <h4>19 Nearest Counties:</h4>
                  <table>
                    <thead>
                      <tr>
                        <th>FIPS</th>
                        <th>Region Name</th>
                        <th>Population</th>
                        <th>GDP</th>
                        <th>Establishments</th>
                        <th>Income</th>
                        <th>Employment</th>
                        <th>Realtorch Index Value</th>
                        <th>Realtorch Index Percentile</th>
                      </tr>
                    </thead>
                    <tbody>
                      {nearestCounties.map((county, index) => {
                        const fipsStr = county.fips.toString().padStart(5, '0');
                        const countyInfo = countyData[fipsStr];
                        return (
                          <tr key={index}>
                            <td>{county.fips}</td>
                            <td>{countyInfo ? countyInfo.region_name : 'Data Not Available'}</td>
                            <ColoredTableCell
                              value={countyInfo ? countyInfo.population_harmonic_mean_percentile : 'NA'}
                              column="population_harmonic_mean_percentile"
                              columnRanges={columnRanges}
                            />
                            <ColoredTableCell
                              value={countyInfo ? countyInfo.gdp_harmonic_mean_percentile : 'NA'}
                              column="gdp_harmonic_mean_percentile"
                              columnRanges={columnRanges}
                            />
                            <ColoredTableCell
                              value={countyInfo ? countyInfo.establishments_harmonic_mean_percentile : 'NA'}
                              column="establishments_harmonic_mean_percentile"
                              columnRanges={columnRanges}
                            />
                            <ColoredTableCell
                              value={countyInfo ? countyInfo.income_harmonic_mean_percentile : 'NA'}
                              column="income_harmonic_mean_percentile"
                              columnRanges={columnRanges}
                            />
                            <ColoredTableCell
                              value={countyInfo ? countyInfo.employment_harmonic_mean_percentile : 'NA'}
                              column="employment_harmonic_mean_percentile"
                              columnRanges={columnRanges}
                            />
                            <ColoredTableCell
                              value={countyInfo ? countyInfo.final_index_value : 'NA'}
                              column="final_index_value"
                              columnRanges={columnRanges}
                            />
                            <ColoredTableCell
                              value={countyInfo ? countyInfo.final_index_percentile : 'NA'}
                              column="final_index_percentile"
                              columnRanges={columnRanges}
                            />
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p>No nearby counties found.</p>
              )}

              {/* Chart Sections */}
              <div className="chart-sections">
                {chartConfigs.map((chart) => (
                  <div key={chart.id} className="chart-container">
                    <h4>{chart.title}</h4>
                    {charts[chart.id].loading && <p>Loading chart...</p>}
                    {charts[chart.id].error && <p className="error">Error loading chart: {charts[chart.id].error}</p>}
                    {charts[chart.id].data && <ChartComponent chartData={charts[chart.id].data} />}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  export default MacroEconMap;
