import React from 'react';
import { Link } from 'react-router-dom';
import MarketEvaluator from './MarketEvaluator';
import ProductEvaluator from './ProductEvaluator';
import ClusteredListings from './ClusteredListings';
import MacroEconMap from './MacroEconMap'; // Import MacroEconMap
import ZipCodeMap from './ZipCodeMap';
import './Home.css';

const Home = ({ 
  user, 
  selectedLocation, 
  selectedDistance, 
  selectedClient, 
  selectedSection, 
  onSectionChange 
}) => {

  return (
    <div className="home-container">
      {user ? (
        <>
          <div className="content">
            <div className="section-buttons">
              <button 
                onClick={() => onSectionChange('market')} 
                className={selectedSection === 'market' ? 'active' : ''}
              >
                Market Evaluator
              </button>
              <button 
                onClick={() => onSectionChange('product')} 
                className={selectedSection === 'product' ? 'active' : ''}
              >
                Product Evaluator
              </button>
              <button 
                onClick={() => onSectionChange('cluster')} 
                className={selectedSection === 'cluster' ? 'active' : ''}
              >
                Listings Evaluator
              </button>
              <button 
                onClick={() => onSectionChange('macro')} // Add MacroEconMap button
                className={selectedSection === 'macro' ? 'active' : ''}
              >
                Macro Economic Index
              </button>
              <button 
                onClick={() => onSectionChange('zipcode')} // Add MacroEconMap button
                className={selectedSection === 'zipcode' ? 'active' : ''}
              >
                Zip Code Map
              </button>
            </div>

            {/* Render the selected section */}
            {selectedSection === 'market' && (
              <MarketEvaluator 
                selectedLocation={selectedLocation} 
                selectedDistance={selectedDistance} 
                selectedClient={selectedClient} 
              />
            )}
            {selectedSection === 'product' && (
              <ProductEvaluator 
                selectedLocation={selectedLocation} 
                selectedDistance={selectedDistance} 
                selectedClient={selectedClient} 
              />
            )}
            {selectedSection === 'cluster' && (
              <ClusteredListings 
                selectedLocation={selectedLocation} 
                selectedDistance={selectedDistance} 
                selectedClient={selectedClient} 
              />
            )}
            {selectedSection === 'macro' && ( // Add MacroEconMap rendering
              <MacroEconMap selectedDistance={selectedDistance} />
            )}
            {selectedSection === 'zipcode' && ( // Add MacroEconMap rendering
              <ZipCodeMap/>
            )}
          </div>
        </>
      ) : (
        <div className="home-container">
          <p>Please log in or sign up to see your application.</p>
          <div className="auth-buttons">
            <Link to="/login">
              <button className="auth-button btn btn-primary">Login</button>
            </Link>
            <Link to="/sign-up">
              <button className="auth-button btn btn-primary">Sign Up</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
