import axios from 'axios';

// Get the API URL from environment variables
const API_URL = process.env.REACT_APP_API_URL;

console.log('API URL:', API_URL); // Log the base URL

// Create an Axios instance with the base URL
const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,  // Include credentials (cookies) in requests if needed
});

// // Log each request
// api.interceptors.request.use(request => {
//   console.log('Starting Request', request);
//   return request;
// });

// // Log each response
// api.interceptors.response.use(response => {
//   console.log('Response:', response);
//   return response;
// });

export default api;
