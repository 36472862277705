// src/components/ColoredTableCell.jsx

import React from 'react';

// Helper functions can be imported or defined here
const hexToRgb = (hex) => {
    hex = hex.replace('#', '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
};

const getColor = (value, min, max) => {
    if (min === max) return '#ffffff';
    const normalized = (value - min) / (max - min);
    const colorStops = [
        { stop: 0, color: '#ffffcc' },
        { stop: 0.2, color: '#c7e9b4' },
        { stop: 0.4, color: '#7fcdbb' },
        { stop: 0.6, color: '#41b6c4' },
        { stop: 0.8, color: '#1d91c0' },
        { stop: 1, color: '#225ea8' },
    ];
    for (let i = 0; i < colorStops.length - 1; i++) {
        if (normalized >= colorStops[i].stop && normalized <= colorStops[i + 1].stop) {
            const relativePos = (normalized - colorStops[i].stop) / (colorStops[i + 1].stop - colorStops[i].stop);
            const color1 = colorStops[i].color;
            const color2 = colorStops[i + 1].color;
            const rgb1 = hexToRgb(color1);
            const rgb2 = hexToRgb(color2);
            const r = Math.round(rgb1.r + (rgb2.r - rgb1.r) * relativePos);
            const g = Math.round(rgb1.g + (rgb2.g - rgb1.g) * relativePos);
            const b = Math.round(rgb1.b + (rgb2.b - rgb1.b) * relativePos);
            return `rgb(${r}, ${g}, ${b})`;
        }
    }
    return '#ffffff';
};

const ColoredTableCell = ({ value, column, columnRanges }) => {
    let displayValue = 'NA';
    let backgroundColor = '#ffffff';

    if (value !== 'NA' && typeof value === 'number' && columnRanges[column]) {
        displayValue = value.toFixed(2); // Adjust decimal places as needed
        backgroundColor = getColor(value, columnRanges[column].min, columnRanges[column].max);
    }

    return (
        <td style={{ backgroundColor }}>
            {displayValue}
        </td>
    );
};

export default ColoredTableCell;
