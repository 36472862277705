// src/App.jsx
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Login from './components/Login';
import SignUp from './components/SignUp';
import MacroEconMap from './components/MacroEconMap';
import api from './api';
import './App.css'
// import MapView from './components/MapView'

const App = () => {
  const [user, setUser] = useState(null); // State for user authentication
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedDistance, setSelectedDistance] = useState(10); // Default distance
  const [selectedSection, setSelectedSection] = useState('market');
  const [isLoading, setIsLoading] = useState(true); // Combined loading state

  // Log user state changes
  useEffect(() => {
    console.log("User state updated:", user);
  }, [user]);

  // Check authentication status on mount
  useEffect(() => {
    const checkAuth = async () => {
      console.log("Checking authentication status...");
      try {
        const resp = await api.get('/@me');
        if (resp.status === 200) {
          console.log('Authenticated user:', resp.data);
          setUser(resp.data); // Set user data
        } else {
          console.log('Authentication check failed with status:', resp.status);
          setUser(null);
        }
      } catch (error) {
        console.log("Not authenticated:", error);
        setUser(null);
      } finally {
        setIsLoading(false); // Authentication check complete
      }
    };
    checkAuth();
  }, []);

  // Fetch clients and locations when user changes
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Start loading
      if (user) {
        if (user.is_admin) {
          console.log("User is admin. Fetching clients...");
          await fetchClients();
        } else {
          console.log("User is not admin. Fetching user-specific locations...");
          await fetchLocations(); // Fetch locations for non-admin user
        }
      } else {
        console.log("User is not logged in. Clearing client and location states.");
        setClients([]);
        setSelectedClient(null);
        setLocations([]);
        setSelectedLocation(null);
      }
      setIsLoading(false); // Loading complete
    };
    fetchData();
  }, [user]); // eslint-disable-line

  // Function to fetch clients (for admin users)
  const fetchClients = async () => {
    try {
      console.log("Fetching clients...");
      const response = await api.get('/api/clients');
      if (response.status === 200) {
        setClients(response.data);
        console.log('Fetched clients:', response.data);
        // If no client is selected, select the first client by default
        if (response.data.length > 0 && !selectedClient) {
          const firstClientId = String(response.data[0].id); // Ensure it's a string
          setSelectedClient(firstClientId);
          console.log('Selected first client:', firstClientId);
          await fetchLocations(firstClientId); // Fetch locations for the first client
        }
      } else {
        console.log('Failed to fetch clients with status:', response.status);
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  // Function to fetch locations based on client ID (for admin users) or user-specific locations
  const fetchLocations = async (clientId = null) => {
    try {
      console.log(`Fetching locations for clientId: ${clientId}`);
      const params = clientId ? { client_id: clientId } : {};
      const response = await api.get('/get-locations', { params });

      if (response.status === 200) {
        setLocations(response.data);
        console.log(`Fetched locations:`, response.data);

        // Set the default selected location to the first one if not already set
        if (response.data.length > 0 && !selectedLocation) {
          const firstLocationId = String(response.data[0].id); // Ensure it's a string
          setSelectedLocation(firstLocationId);
          console.log('Selected first location:', firstLocationId);
        }
      } else {
        console.log('Failed to fetch locations with status:', response.status);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  // Handle client change (for admin users)
  const handleClientChange = async (clientId) => {
    console.log(`Changing selected client to: ${clientId}`);
    setSelectedClient(clientId);
    await fetchLocations(clientId); // Fetch locations for the selected client
  };

  // Handle location change
  const handleLocationChange = (locationId) => {
    console.log(`Changing selected location to: ${locationId}`);
    setSelectedLocation(locationId);
  };

  // Handle distance change
  const handleDistanceChange = (distance) => {
    console.log(`Changing selected distance to: ${distance}`);
    setSelectedDistance(distance);
  };

  // Handle section change
  const handleSectionChange = (section) => {
    console.log(`Changing selected section to: ${section}`);
    setSelectedSection(section);
  };

  // Handle logout by clearing all relevant states
  const handleLogout = () => {
    console.log("Logging out. Clearing all user-related states.");
    setUser(null);
    setClients([]);
    setSelectedClient(null);
    setLocations([]);
    setSelectedLocation(null);
    setSelectedDistance(10);
    setSelectedSection('market');
    // Optionally, navigate to login if not handled elsewhere
  };

  if (isLoading) {
    return (
      <div className="container mt-5 pt-5">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <Router>
      <Navbar 
        user={user}
        setUser={setUser}
        clients={clients}
        selectedClient={selectedClient}
        onClientChange={handleClientChange}
        locations={locations}
        selectedLocation={selectedLocation}
        onLocationChange={handleLocationChange}
        selectedDistance={selectedDistance}
        onDistanceChange={handleDistanceChange}
        onLogout={handleLogout} // Pass the logout handler
      />
      <div className="container mt-5 pt-5">
        <Routes>
          <Route 
            path="/" 
            element={
              <Home 
                user={user}
                selectedLocation={selectedLocation}
                selectedDistance={selectedDistance}
                selectedClient={selectedClient}
                selectedSection={selectedSection}
                onSectionChange={handleSectionChange}
              />
            } 
          />
          <Route path="/login" element={<Login setUser={setUser}/>} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/macro-econ-index" element={<MacroEconMap />} />
          {/* <Route path="/map" element={<MapView />} /> */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
