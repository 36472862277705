import React, { useEffect, useState } from 'react';

// Function to calculate background color based on value with RGB interpolation
const getBackgroundColor = (value, startColor = [224, 232, 216], endColor = [245, 88, 22]) => {
  const interpolate = (start, end, factor) => start + (end - start) * factor;

  const r = Math.round(interpolate(startColor[0], endColor[0], value));
  const g = Math.round(interpolate(startColor[1], endColor[1], value));
  const b = Math.round(interpolate(startColor[2], endColor[2], value));

  return `rgb(${r}, ${g}, ${b})`;
};

const HeatmapTable = ({ chartData }) => {
  const [data, setData] = useState([]);

  // Columns mapping
  const columns = ["region", "gdp", "population", "establishments", "income", "employment", "index"];

  useEffect(() => {
    if (chartData) {
      const sql_json = JSON.parse(chartData.sql_json);
      const transformedData = transformData(sql_json);
      setData(transformedData);
    }
  }, [chartData]);

  // Transform data from the API format to the desired format
  const transformData = (rawData) => {
    const keys = Object.keys(rawData.region_name);
    return keys.map(key => ({
      region: rawData.region_name[key],
      gdp: rawData.gdp_harmonic_mean[key],
      population: rawData.population_harmonic_mean[key],
      establishments: rawData.establishments_harmonic_mean[key],
      income: rawData.estimate_of_median_household_income_cdf[key],
      employment: rawData.employment_harmonic_mean[key],
      index: rawData.final_index_value[key],
    }));
  };

  return (
    <div>
      <h2>County Economic Indicators Heatmap</h2>
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <thead>
          <tr>
            {columns.map(col => (
              <th key={col} style={{ border: '1px solid black', padding: '5px', textAlign: 'left' }}>{col.toUpperCase()}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={row.region}>
              {columns.map(col => (
                <td
                  key={col}
                  style={{
                    border: '1px solid black',
                    padding: '5px',
                    backgroundColor: col === 'region' ? 'white' : getBackgroundColor(row[col]),
                    textAlign: col === 'region' ? 'left' : 'center'
                  }}
                >
                  {col === 'region' ? row[col] : row[col].toFixed(3)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HeatmapTable;
