import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

// Fetch GeoJSON data for counties
const fetchGeoJson = async () => {
  const response = await fetch('https://raw.githubusercontent.com/plotly/datasets/master/geojson-counties-fips.json');
  const data = await response.json();
  return data;
};

// Function to normalize FIPS codes to 5-digit strings
const normalizeFips = (fips) => {
  return fips.toString().padStart(5, '0');
};

// Transform SQL JSON to a usable format
const transformSqlJson = (sql_json) => {
  if (!sql_json.fips || !sql_json.final_index_value || !sql_json.region_name) {
    console.error('Invalid sql_json structure:', sql_json);
    return [];
  }

  return Object.keys(sql_json.fips).map(key => ({
    fips: normalizeFips(sql_json.fips[key]),  // Normalize FIPS
    final_index_value: sql_json.final_index_value[key],
    region_name: sql_json.region_name[key] // Include region_name
  }));
};

// Interpolate between two colors
const interpolateColor = (startColor, endColor, factor) => {
  const result = startColor.slice();
  for (let i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (endColor[i] - startColor[i]));
  }
  return result;
};

const ChoroplethMap = ({ data }) => {
  const sql_json = JSON.parse(data.sql_json);

  // Add logging to check parsed data
  console.log('Parsed sql_json:', sql_json);

  const transformedData = transformSqlJson(sql_json);
  const [geoJson, setGeoJson] = useState(null);

  useEffect(() => {
    const loadGeoJson = async () => {
      const geoJsonData = await fetchGeoJson();
      setGeoJson(geoJsonData);
    };

    loadGeoJson();
  }, []);

  const getStyle = (feature) => {
    const fipsCode = feature.id; // Assuming feature.id holds the FIPS code
    const matchingData = transformedData.find(d => d.fips === fipsCode);
    const value = matchingData ? matchingData.final_index_value : 0;

    // Define color gradient from white to dark orange
    const startColor = [255, 255, 255]; // White
    const endColor = [255, 87, 0]; // Dark Orange

    // Interpolate color
    const interpolatedColor = interpolateColor(startColor, endColor, value);
    const color = `rgb(${interpolatedColor[0]}, ${interpolatedColor[1]}, ${interpolatedColor[2]})`;

    return {
      fillColor: color,
      weight: 1,
      opacity: 1,
      color: 'white',
      fillOpacity: 0.7,
    };
  };

  const onEachFeature = (feature, layer) => {
    const fipsCode = feature.id;
    const matchingData = transformedData.find(d => d.fips === fipsCode);
    const regionName = matchingData ? matchingData.region_name : 'Unknown region';
    const finalIndexValue = matchingData ? matchingData.final_index_value.toFixed(3) : 'N/A';

    layer.bindPopup(`<strong>Region:</strong> ${regionName}<br><strong>Index Value:</strong> ${finalIndexValue}`);
    
    // Optionally, if you want to show region name on hover instead of click
    layer.on('mouseover', function (e) {
      layer.openPopup();
    });
    layer.on('mouseout', function (e) {
      layer.closePopup();
    });
  };

  if (!geoJson) return <div>Loading map...</div>;

  return (
    <MapContainer center={[43.0, -85.5]} zoom={6} style={{ height: '500px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <GeoJSON data={geoJson} style={getStyle} onEachFeature={onEachFeature} />
    </MapContainer>
  );
};

export default ChoroplethMap;
