import React, { useState } from 'react';
// import axios from 'axios';
import api from '../api';
import { useNavigate } from 'react-router-dom';

const SignUp = ({ setUser, setMessages }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/sign-up', {
        email,
        name,
        password1,
        password2,
      }, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.data.success) {
        alert(response.data.message); // Show success message
        navigate('/login'); // Navigate to login page on success
      } else {
        alert(response.data.message); // Show error message
      }
    } catch (error) {
      if (error.response && error.response.data) {
        alert(error.response.data.message); // Show error message from server
      } else {
        alert('An error occurred. Please try again.'); // Generic error message
      }
    }
  };

  return (
    <div className="signup-container">
      <form onSubmit={handleSubmit} className="signup-form">
        <h3 align="center">Sign Up</h3>
        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder="Enter name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password1">Password</label>
          <input
            type="password"
            className="form-control"
            id="password1"
            name="password1"
            placeholder="Enter password"
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password2">Confirm Password</label>
          <input
            type="password"
            className="form-control"
            id="password2"
            name="password2"
            placeholder="Confirm password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            required
          />
        </div>
        <div>
          <br />
          <button type="submit" className="btn btn-primary">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
